
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


if ('serviceWorker' in navigator ) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('service-worker.js').then(function(registration) {
            // console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
            // console.log('ServiceWorker registration failed: ', err);
        });
    });
}


